<template>
  <div style="height:calc(100vh - 100px)">
    <div class="callCenter">
      <div class="callCenter_img" @click="goTo">
        <img src="../../assets/imgs/callCenter.png" alt="">
        <span class="callCenter_text">进入呼叫中心工作台</span>
      </div>
      <!-- <el-button type="primary" @click="goTo">进入呼叫中心工作台</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  // 呼叫中心
  name: 'callCenter',
  methods: {
    goTo() {
      let url = null
      const developReg = /(localhost)|(0\.0\.0\.0)|(192.168)/
      const testReg = /(cirbd.cn)/
      const url_dev = 'https://call.cirbd.cn/#/login'
      const url_prod = 'https://hjzx.huinian.net.cn/#/login'
      url = url_prod
      if (developReg.test(location.href) || testReg.test(location.href)) {
        url = url_dev
      }
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.callCenter {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .callCenter_img{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    width: 2.60416rem;
    height: 1.25rem;
    background-color: antiquewhite;
    background: url("../../assets/imgs/callCenterBg.png");
    background-repeat: no-repeat;
    background-size:cover
  }
  img {
      width:0.83333rem;
    }
    .callCenter_text{
      color: #ffffff;
      font-size: 0.13020rem;
      margin-left: 20px;
    }
}
</style>
